//##############################################################
// CRUD
const MODEL_PATH = "on-boarding";
// END CRUD
//##############################################################

//##############################################################
// CUSTOM ACTIONS
// TODO: Write here your custom paths
export const UPDATE_PASSWORD_PATH = `custom-endpoints/${MODEL_PATH}/update-password`;
export const COMPLETE_ACCOUNT_DATA_PATH = `custom-endpoints/${MODEL_PATH}/complete-account-data`;
// CUSTOM ACTIONS
// ##############################################################

import {
  DELETE_ONE,
  DELETE_ONE_ERROR,
  DELETE_ONE_SUCCESS,
  GET,
  GET_BY_USERNAME,
  GET_BY_USERNAME_ERROR,
  GET_BY_USERNAME_SUCCESS,
  GET_ERROR,
  GET_SUCCESS,
  LIST,
  LIST_ERROR,
  LIST_SUCCESS,
  POST,
  POST_ERROR,
  POST_SUCCESS,
  CREATE,
  CREATE_ERROR,
  CREATE_SUCCESS,
  PUT,
  PUT_ERROR,
  PUT_SUCCESS,
  UPDATE,
  UPDATE_SUCCESS,
  UPDATE_ERROR,
} from './constants';

//##############################################################
// CRUD
export const post = (payload) => ({
  type: POST,
  payload: payload
});
export const postSuccess = (payload) => ({
  type: POST_SUCCESS,
  payload: payload
});
export const postError = (payload) => ({
  type: POST_ERROR,
  payload: payload
});
export const put = (payload) => ({
  type: PUT,
  payload: payload
});
export const putSuccess = (payload) => ({
  type: PUT_SUCCESS,
  payload: payload
});
export const putError = (payload) => ({
  type: PUT_ERROR,
  payload: payload
});
export const list = (payload) => ({
  type: LIST,
  payload: payload
});
export const listSuccess = (payload) => ({
  type: LIST_SUCCESS,
  payload: payload
});
export const listError = (payload) => ({
  type: LIST_ERROR,
  payload: payload
});
export const get = (payload) => ({
  type: GET,
  payload: payload
});
export const getSuccess = (payload) => ({
  type: GET_SUCCESS,
  payload: payload
});
export const getError = (payload) => ({
  type: GET_ERROR,
  payload: payload
});
export const deleteOne = (payload) => ({
  type: DELETE_ONE,
  payload: payload
});
export const deleteOneSuccess = (payload) => ({
  type: DELETE_ONE_SUCCESS,
  payload: payload
});
export const deleteOneError = (payload) => ({
  type: DELETE_ONE_ERROR,
  payload: payload
});
// END CRUD
//##############################################################

//##############################################################
// CUSTOM ACTIONS
// TODO: Write here your custom actions
export const getByUsername = (objectID, params) => ({
  type: GET_BY_USERNAME,
  payload: { objectID, params }
});
export const getByUsernameSuccess = (payload) => ({
  type: GET_BY_USERNAME_SUCCESS,
  payload: payload
});
export const getByUsernameError = (payload) => ({
  type: GET_BY_USERNAME_ERROR,
  payload: payload
});
export const create = (payload, history) => ({
  type: CREATE,
  payload,
  history
});
export const createSuccess = (payload) => ({
  type: CREATE_SUCCESS,
  payload
});
export const createError = (payload) => ({
  type: CREATE_ERROR,
  payload: payload
});
export const update = (payload, history) => ({
  type: UPDATE,
  payload,
  history
});
export const updateSuccess = (payload) => ({
  type: UPDATE_SUCCESS,
  payload
});
export const updateError = (payload) => ({
  type: UPDATE_ERROR,
  payload: payload
});
// CUSTOM ACTIONS
// ##############################################################

import {
  DELETE_ONE_INITIAL_STATE,
  GET_INITIAL_STATE,
  LIST_INITIAL_STATE,
  POST_INITIAL_STATE,
  PUT_INITIAL_STATE,
  CONTRACT_VIDEO_UPLOAD_INITIAL_STATE,
  CONTRACT_REJECT_INITIAL_STATE
} from "../initial_states";
import { NotificationManager } from "../../components/common/react-notifications";
import {
  DELETE_ONE,
  DELETE_ONE_ERROR,
  DELETE_ONE_SUCCESS,
  GET,
  GET_ERROR,
  GET_SUCCESS,
  LIST,
  LIST_ERROR,
  LIST_SUCCESS,
  POST,
  POST_ERROR,
  POST_SUCCESS,
  PUT,
  PUT_ERROR,
  PUT_SUCCESS,
  ACCEPT_CONTRACT,
  SET_VIDEO_UPLOAD_PROGRESS,
  START_CONTRACT_VIDEO_UPLOAD,
  ACCEPT_CONTRACT_SUCCESS,
  ACCEPT_CONTRACT_ERROR,
  REJECT_CONTRACT,
  RESET_REJECT_CONTRACT,
  REJECT_CONTRACT_SUCCESS,
  REJECT_CONTRACT_ERROR,
  RESTART_CONTRACT_VIDEO_UPLOAD
} from "./constants";
import IntlMessages from "../../components/helpers/IntlMessages";
import React from "react";

const INIT_STATE = {
  // CRUD
  post: POST_INITIAL_STATE,
  put: PUT_INITIAL_STATE,
  list: LIST_INITIAL_STATE,
  get: GET_INITIAL_STATE,
  deleteOne: DELETE_ONE_INITIAL_STATE,
  acceptContract: CONTRACT_VIDEO_UPLOAD_INITIAL_STATE,
  rejectContract: CONTRACT_REJECT_INITIAL_STATE
};

export default (state = INIT_STATE, action) => {
  let res;
  switch (action.type) {
    //##############################################################
    // CRUD
    case POST:
      res = { ...state };
      res.post = { ...POST_INITIAL_STATE };
      res.post.loading = true;
      return res;
    case POST_SUCCESS:
      res = { ...state };
      res.post = { ...POST_INITIAL_STATE };
      res.post.data = action.payload.data;
      return res;
    case POST_ERROR:
      res = { ...state };
      res.post = { ...POST_INITIAL_STATE };
      res.post.errorData = action.payload;
      NotificationManager.warning(
        res.post.errorData.error,
        "Oops!",
        3000,
        null,
        null,
        ""
      );
      return res;
    case PUT:
      res = { ...state };
      res.put = { ...PUT_INITIAL_STATE };
      res.put.loading = true;
      return res;
    case PUT_SUCCESS:
      res = { ...state };
      res.put = { ...PUT_INITIAL_STATE };
      res.put.data = action.payload.data;
      NotificationManager.success(
        <IntlMessages id="notifications.celebrities.update.description" />,
        <IntlMessages id="notifications.celebrities.update.title" />,
        3000,
        null,
        null,
        ""
      );
      return res;
    case PUT_ERROR:
      res = { ...state };
      res.put = { ...PUT_INITIAL_STATE };
      res.put.errorData = action.payload;
      NotificationManager.warning(
        res.put.errorData.error,
        "Oops!",
        3000,
        null,
        null,
        ""
      );
      return res;
    case LIST:
      res = { ...state };
      res.list = { ...LIST_INITIAL_STATE };
      res.list.loading = true;
      return res;
    case LIST_SUCCESS:
      res = { ...state };
      res.list = { ...LIST_INITIAL_STATE };
      res.list.results = action.payload.results;
      res.list.informationPage = action.payload.informationPage;
      return res;
    case LIST_ERROR:
      res = { ...state };
      res.list = { ...LIST_INITIAL_STATE };
      res.list.errorData = action.payload;
      NotificationManager.warning(
        res.list.errorData.error,
        "Oops!",
        3000,
        null,
        null,
        ""
      );
      return res;
    case GET:
      res = { ...state };
      res.get = { ...GET_INITIAL_STATE };
      res.get.loading = true;
      return res;
    case GET_SUCCESS:
      res = { ...state };
      res.get = { ...GET_INITIAL_STATE };
      res.get.data = action.payload.data;
      return res;
    case GET_ERROR:
      res = { ...state };
      res.get = { ...GET_INITIAL_STATE };
      res.get.errorData = action.payload;
      NotificationManager.warning(
        res.get.errorData.error,
        "Oops!",
        3000,
        null,
        null,
        ""
      );
      return res;
    case DELETE_ONE:
      res = { ...state };
      res.delete = { ...DELETE_ONE_INITIAL_STATE };
      res.delete.loading = true;
      return res;
    case DELETE_ONE_SUCCESS:
      res = { ...state };
      res.delete = { ...DELETE_ONE_INITIAL_STATE };
      res.delete.data = action.payload.data;
      return res;
    case DELETE_ONE_ERROR:
      res = { ...state };
      res.delete = { ...DELETE_ONE_INITIAL_STATE };
      res.delete.errorData = action.payload;
      NotificationManager.warning(
        res.delete.errorData.error,
        "Oops!",
        3000,
        null,
        null,
        ""
      );
      return res;
    // CRUD
    //##############################################################
    case START_CONTRACT_VIDEO_UPLOAD:
      res = { ...state };
      res.acceptContract = {
        ...CONTRACT_VIDEO_UPLOAD_INITIAL_STATE,
        isUploading: true
      };
      return res;

    case SET_VIDEO_UPLOAD_PROGRESS:
      res = { ...state };
      res.acceptContract = {
        ...state.acceptContract,
        progress: action.payload
      };
      return res;

    case ACCEPT_CONTRACT_SUCCESS:
      res = { ...state };
      res.acceptContract = { ...CONTRACT_VIDEO_UPLOAD_INITIAL_STATE };
      res.acceptContract.data = action.payload;
      return res;

    case ACCEPT_CONTRACT_ERROR:
      res = { ...state };
      res.acceptContract = { ...CONTRACT_VIDEO_UPLOAD_INITIAL_STATE };
      res.acceptContract.errorData = action.payload;
      return res;

    case RESTART_CONTRACT_VIDEO_UPLOAD:
      res = { ...state };
      res.acceptContract = { ...CONTRACT_VIDEO_UPLOAD_INITIAL_STATE };
      return res;

    case REJECT_CONTRACT:
      res = { ...state };
      res.rejectContract = { ...CONTRACT_REJECT_INITIAL_STATE };
      res.rejectContract.isLoading = true;
      return res;

    case REJECT_CONTRACT_SUCCESS:
      res = { ...state };
      res.rejectContract = { ...CONTRACT_REJECT_INITIAL_STATE };
      res.rejectContract.data = action.payload;
      return res;

    case REJECT_CONTRACT_ERROR:
      res = { ...state };
      res.rejectContract = { ...CONTRACT_REJECT_INITIAL_STATE };
      res.rejectContract.errorData = action.payload;
      return res;

    case RESET_REJECT_CONTRACT:
      res = { ...state };
      res.rejectContract = { ...CONTRACT_REJECT_INITIAL_STATE };
      return res;
    default:
      return { ...state };
  }
};

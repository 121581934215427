import {
    POST,
    POST_SUCCESS,
    POST_ERROR,
    LIST,
    LIST_SUCCESS,
    LIST_ERROR,
    NOT_PAGINATED_LIST,
    NOT_PAGINATED_LIST_SUCCESS,
    NOT_PAGINATED_LIST_ERROR,
    GET,
    GET_SUCCESS,
    GET_ERROR,
    PUT,
    PUT_SUCCESS,
    PUT_ERROR,
    DELETE_ONE,
    DELETE_ONE_SUCCESS,
    DELETE_ONE_ERROR
} from "./constants";
import { CRUD_NOT_PAGINATED_LIST_PATH } from "../celebrity-categories/paths";

//##############################################################
// CRUD
export const post = (payload) => ({
    type: POST,
    payload: payload
});
export const postSuccess = (payload) => ({
    type: POST_SUCCESS,
    payload: payload
});
export const postError = (payload) => ({
    type: POST_ERROR,
    payload: payload
});
export const put = (payload) => ({
    type: PUT,
    payload: payload
});
export const putSuccess = (payload) => ({
    type: PUT_SUCCESS,
    payload: payload
});
export const putError = (payload) => ({
    type: PUT_ERROR,
    payload: payload
});
export const list = (payload) => ({
    type: CRUD_NOT_PAGINATED_LIST_PATH,
    payload: payload
});
export const listSuccess = (payload) => ({
    type: LIST_SUCCESS,
    payload: payload
});
export const listError = (payload) => ({
    type: LIST_ERROR,
    payload: payload
});
export const notPaginatedList = (payload) => ({
    type: NOT_PAGINATED_LIST,
    payload: payload
});
export const notPaginatedListSuccess = (payload) => ({
    type: NOT_PAGINATED_LIST_SUCCESS,
    payload: payload
});
export const notPaginatedListError = (payload) => ({
    type: NOT_PAGINATED_LIST_ERROR,
    payload: payload
});
export const get = (payload) => ({
    type: GET,
    payload: payload
});
export const getSuccess = (payload) => ({
    type: GET_SUCCESS,
    payload: payload
});
export const getError = (payload) => ({
    type: GET_ERROR,
    payload: payload
});
export const deleteOne = (payload) => ({
    type: DELETE_ONE,
    payload: payload
});
export const deleteOneSuccess = (payload) => ({
    type: DELETE_ONE_SUCCESS,
    payload: payload
});
export const deleteOneError = (payload) => ({
    type: DELETE_ONE_ERROR,
    payload: payload
});
// END CRUD
//##############################################################

//##############################################################
// CUSTOM ACTIONS
// TODO: Write here your custom actions

// CUSTOM ACTIONS
// ##############################################################

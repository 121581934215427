import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import * as GTM from "./constants/GTM";

const App = lazy(() => import("./App"));
const rootElement = document.getElementById("root");

GTM.initialize();

ReactDOM.render(
	<Provider store={configureStore()}>
		<Suspense fallback={<div className="loading" />}>
			<App />
		</Suspense>
	</Provider>,
	rootElement
);

serviceWorker.unregister();

import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import apiService from "../api_service";
import {
  DELETE_ONE,
  GET,
  GET_BY_USERNAME,
  LIST,
  POST,
  PUT,
  CREATE,
  UPDATE,
} from "./constants";
import {
  deleteOneError,
  deleteOneSuccess,
  getByUsernameError,
  getByUsernameSuccess,
  getError,
  getSuccess,
  listError,
  listSuccess,
  postError,
  postSuccess,
  createError,
  createSuccess,
  putError,
  putSuccess,
  updateSuccess,
updateError
} from "./actions";
import {
  CRUD_DELETE_PATH,
  CRUD_GET_PATH,
  LIST_PATH,
  CRUD_POST_PATH,
  CRUD_PUT_PATH,
  GET_BY_USERNAME_PATH,
  CREATE_PATH,
  GET_BALANCE,
  GET_CONTRACTS_GROUP_BY_STATUS_SUMMARY,
  UPDATE_PATH,
} from "./paths";
import { NotificationManager } from "../../components/common/react-notifications";
import { CELEBRITIES_LIST_PATH } from "../../routing/paths";

//#######################
// LIST
export function* watchList() {
  yield takeEvery(LIST, listSaga);
}

export const listSagaAsync = async (params) => {
  return new Promise(function (resolve, reject) {
    apiService({
      method: "GET",
      path: LIST_PATH,
      params,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response.data : {}));
  });
};

function* listSaga({ payload }) {
  try {
    const response = yield call(listSagaAsync, payload);
    if (response.status === "OK") {
      yield put(listSuccess(response));
    } else {
      yield put(listError(response));
    }
  } catch (e) {
    yield put(listError(e));
  }
}

// END LIST
//#######################

//#######################
// GET
export function* watchGet() {
  yield takeEvery(GET, getSaga);
}

const getSagaAsync = async (objectId) => {
  return new Promise(function (resolve, reject) {
    apiService({
      method: "GET",
      path: CRUD_GET_PATH + objectId,
      custom_endpoint: "https://api.myjson.com/bins/12zp2o",
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response.data : {}));
  });
};

function* getSaga({ payload }) {
  try {
    const response = yield call(getSagaAsync, payload);
    if (response.status === "OK") {
      yield put(getSuccess(response));
    } else {
      yield put(getError(response));
    }
  } catch (e) {
    yield put(getError(e));
  }
}

// END GET
//#######################

//#######################
// POST
export function* watchPost() {
  yield takeEvery(POST, postSaga);
}

const postSagaAsync = async (body) => {
  return new Promise(function (resolve, reject) {
    apiService({
      method: "POST",
      path: CRUD_POST_PATH,
      body: body,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response.data : {}));
  });
};

function* postSaga({ payload }) {
  try {
    const response = yield call(postSagaAsync, payload);
    if (response.status === "OK") {
      yield put(postSuccess(response));
    } else {
      yield put(postError(response));
    }
  } catch (e) {
    yield put(postError(e));
  }
}

// END POST
//#######################

//#######################
// PUT
export function* watchPut() {
  yield takeEvery(PUT, putSaga);
}

const putSagaAsync = async (objectId, body) => {
  return new Promise(function (resolve, reject) {
    apiService({
      method: "PUT",
      path: CRUD_PUT_PATH + objectId,
      body: body,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response.data : {}));
  });
};

function* putSaga({ payload }) {
  try {
    const response = yield call(putSagaAsync, payload);
    if (response.status === "OK") {
      yield put(putSuccess(response));
    } else {
      yield put(putError(response));
    }
  } catch (e) {
    yield put(putError(e));
  }
}

// END PUT
//#######################

//#######################
// DELETE
export function* watchDeleteOne() {
  yield takeEvery(DELETE_ONE, deleteOneSaga);
}

const deleteOneSagaAsync = async (objectId) => {
  return new Promise(function (resolve, reject) {
    apiService({
      method: "DELETE",
      path: CRUD_DELETE_PATH + objectId,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response.data : {}));
  });
};

function* deleteOneSaga({ payload }) {
  try {
    const response = yield call(deleteOneSagaAsync, payload);
    if (response.status === "OK") {
      yield put(deleteOneSuccess(response));
    } else {
      yield put(deleteOneError(response));
    }
  } catch (e) {
    yield put(deleteOneError(e));
  }
}

// END DELETE
//#######################

//#######################
// GET_BY_USERNAME
export function* watchGetByusername() {
  yield takeEvery(GET_BY_USERNAME, getByUsernameSaga);
}

const getByUsernameSagaAsync = async ({ objectID, params }) => {
  return new Promise(function (resolve, reject) {
    apiService({
      method: "GET",
      path: GET_BY_USERNAME_PATH + objectID,
      params: params,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response.data : {}));
  });
};

function* getByUsernameSaga({ payload }) {
  const response = yield call(getByUsernameSagaAsync, payload);
  if (response.status === "OK") {
    yield put(getByUsernameSuccess(response));
  } else {
    yield put(getByUsernameError(response));
  }
}

// END GET_BY_USERNAME
//#######################
//#######################
// CREATE
export function* watchCreate() {
  yield takeEvery(CREATE, create);
}

const createAsync = async (body) => {
  try {
    const response = await apiService({
      async: true,
      path: CREATE_PATH,
      method: "POST",
      params: null,
      body,
    });
    return response.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { error: "Error inesperado del servidor" };
  }
};

function* create({ payload, history }) {
  try {
    const createData = yield call(createAsync, payload);
    if (createData.status !== "OK") {
      throw createData.error;
    }
    yield put(createSuccess());
    NotificationManager.success(
      "El famosos a sido creado exitosamente",
      "Completado",
      3000
    );
    history.push(CELEBRITIES_LIST_PATH);
  } catch (error) {
    yield put(createError(error));
    NotificationManager.warning(error, "Oops!", 3000);
  }
}
// END CREATE
//#######################
//#######################
// CREATE
export function* watchupdate() {
  yield takeEvery(UPDATE, update);
}

const updateAsync = async (body) => {
  try {
    const response = await apiService({
      async: true,
      path: UPDATE_PATH,
      method: "PUT",
      params: null,
      body,
    });
    return response.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { error: "Error inesperado del servidor" };
  }
};

function* update({ payload, history }) {
  try {
    const updateData = yield call(updateAsync, payload);
    if (updateData.status !== "OK") {
      throw updateData.error;
    }
    yield put(updateSuccess());
    NotificationManager.success(
      "El famosos a sido modificado exitosamente",
      "Completado",
      3000
    );
    history.push(CELEBRITIES_LIST_PATH);
  } catch (error) {
    yield put(updateError(error));
    NotificationManager.warning(error, "Oops!", 3000);
  }
}
// END CREATE
//#######################
//#######################
// GET INSTAGRAM MEDIAS
export const getInstagramMediasSagaAsync = async (username) => {
  return new Promise(function (resolve, reject) {
    apiService({
      method: "GET",
      path: "https://www.instagram.com/" + username + "/?__a=1",
      custom_endpoint: true,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response.data : {}));
  });
};
// END GET INSTAGRAM MEDIAS
//#######################
// GET INSTAGRAM MEDIAS
export const getBalanceSagaAsync = async (celebrity_id) => {
  return new Promise(function (resolve, reject) {
    apiService({
      method: "GET",
      path: GET_BALANCE + celebrity_id,
      custom_endpoint: false,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response.data : {}));
  });
};
// END GET INSTAGRAM MEDIAS
//#######################

export const contractsGroupByStatusSagaAsync = async (celebrity_id) => {
  return new Promise(function (resolve, reject) {
    apiService({
      method: "GET",
      path: GET_CONTRACTS_GROUP_BY_STATUS_SUMMARY + celebrity_id,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response : {}));
  });
};

export default function* rootSaga() {
  yield all([
    fork(watchList),
    fork(watchGet),
    fork(watchPut),
    fork(watchPost),
    fork(watchCreate),
    fork(watchupdate),
    fork(watchDeleteOne),
    fork(watchGetByusername),
  ]);
}

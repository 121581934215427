import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  updatePasswordSuccess,
  updatePasswordError,
  completeAccountDataSuccess,
  completeAccountDataError
} from "./actions";
import {
  ON_BOARDING_COMPLETE_ACCOUNT_DATA_PATH,
  ROOT_PATH
} from "../../routing/paths";
import apiService from "../api_service";
import { Session } from "../session";
import { UPDATE_PASSWORD_PATH, COMPLETE_ACCOUNT_DATA_PATH } from "./paths";
import { UPDATE_PASSWORD, COMPLETE_ACCOUNT_DATA } from "./constants";
import { NotificationManager } from "../../components/common/react-notifications";

//#######################
// UPDATE PASSWORD
export function* watchUpdatePassword() {
  yield takeEvery(UPDATE_PASSWORD, updatePassword);
}

const redirectUser = (requiredAction, history) => {
  let redirectRoute = ROOT_PATH;
  if (requiredAction === COMPLETE_ACCOUNT_DATA) {
    redirectRoute = ON_BOARDING_COMPLETE_ACCOUNT_DATA_PATH;
  }
  history.push(redirectRoute);
};

const updatePasswordAsync = async (newPassword) => {
  try {
    const response = await apiService({
      async: true,
      path: UPDATE_PASSWORD_PATH,
      method: "PUT",
      body: { newPassword }
    });
    return response.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { error: "Error inesperado del servidor" };
  }
};

function* updatePassword({ payload, history }) {
  const { newPassword } = payload;
  try {
    const updatePasswordData = yield call(updatePasswordAsync, newPassword);
    if (updatePasswordData.status !== "OK") throw updatePasswordData.error;
    yield put(updatePasswordSuccess());
    new Session().setSession(updatePasswordData.data.token);
    redirectUser(updatePasswordData.data.requiredAction, history);
  } catch (error) {
    yield put(updatePasswordError(error));
    NotificationManager.warning(error, "Oops!", 3000);
  }
}

// END UPDATE PASSWORD
//#######################

//#######################
// UPDATE PASSWORD
export function* watchCompleteAccountData() {
  yield takeEvery(COMPLETE_ACCOUNT_DATA, completeAccountData);
}

const completeAccountDataAsync = async (body) => {
  try {
    const response = await apiService({
      async: true,
      path: COMPLETE_ACCOUNT_DATA_PATH,
      method: "PUT",
      body
    });
    return response.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { error: "Error inesperado del servidor" };
  }
};

function* completeAccountData({ payload, history }) {
  try {
    const completeAccountDataData = yield call(
      completeAccountDataAsync,
      payload
    );
    if (completeAccountDataData.status !== "OK") {
      throw completeAccountDataData.error;
    }
    yield put(completeAccountDataSuccess());
    new Session().setSession(completeAccountDataData.data.token);
    history.push(ROOT_PATH);
  } catch (error) {
    yield put(completeAccountDataError(error));
    NotificationManager.warning(error, "Oops!", 3000);
  }
}

// END UPDATE PASSWORD
//#######################

export default function* rootSaga() {
  yield all([fork(watchUpdatePassword), fork(watchCompleteAccountData)]);
}

import { combineReducers } from "redux";
import settings from "./settings/reducer";
import menu from "./menu/reducer";
import authentication from "./authentication/reducer";
import celebrities from "./celebrities/reducer";
import countries from "./countries/reducer";
import celebrityCategories from "./celebrity-categories/reducer";
import celebrityContractTypes from "./celebrity-contract-types/reducer";
import onboarding from "./onboarding/reducer";
import contracts from "./contracts/reducer";
import celebritySocialNetworks from "./celebrity-social-networks/reducer";

const reducers = combineReducers({
	menu,
	settings,
	authentication,
	onboarding,
	celebrities,
	contracts,
	countries,
	celebrityCategories,
	celebrityContractTypes,
	celebritySocialNetworks
});

export default reducers;

import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import apiService from "../api_service";
import {
  DELETE_ONE,
  GET,
  LIST,
  POST,
  PUT,
  ACCEPT_CONTRACT,
  REJECT_CONTRACT
} from "./constants";
import {
  deleteOneError,
  deleteOneSuccess,
  getError,
  getSuccess,
  listError,
  listSuccess,
  postError,
  postSuccess,
  putError,
  putSuccess,
  acceptContractError,
  acceptContractSuccess,
  rejectContractError,
  rejectContractSuccess
} from "./actions";
import {
  CRUD_DELETE_PATH,
  CRUD_GET_PATH,
  CRUD_POST_PATH,
  CRUD_PUT_PATH,
  CUSTOM_ENDPOINTS_ACCEPT_OR_REJECT_CONTRACT_PATH,
  CUSTOM_ENDPOINTS_GET_UPLOAD_LINK,
  LIST_PATH
} from "./paths";
import { NotificationManager } from "../../components/common/react-notifications";

//#######################
// LIST
export function* watchList() {
  yield takeEvery(LIST, listSaga);
}

export const listSagaAsync = async (params) => {
  return new Promise(function (resolve, reject) {
    apiService({
      method: "GET",
      path: LIST_PATH,
      params: params
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response : {}));
  });
};

function* listSaga({ payload }) {
  try {
    const response = yield call(listSagaAsync, payload);
    if (response.status === "OK") {
      yield put(listSuccess(response));
    } else {
      yield put(listError(response));
    }
  } catch (e) {
    yield put(listError(e.data ? e.data : e));
  }
}

// END LIST
//#######################

//#######################
// GET
export function* watchGet() {
  yield takeEvery(GET, getSaga);
}

const getSagaAsync = async (objectId) => {
  return new Promise(function (resolve, reject) {
    apiService({
      method: "GET",
      path: CRUD_GET_PATH + objectId
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response : {}));
  });
};

function* getSaga({ payload }) {
  try {
    const response = yield call(getSagaAsync, payload);
    if (response.status === "OK") {
      yield put(getSuccess(response));
    } else {
      yield put(getError(response));
    }
  } catch (e) {
    yield put(getError(e.data ? e.data : e));
  }
}

// END GET
//#######################

//#######################
// POST
export function* watchPost() {
  yield takeEvery(POST, postSaga);
}

const postSagaAsync = async (body) => {
  return new Promise(function (resolve, reject) {
    apiService({
      method: "POST",
      path: CRUD_POST_PATH,
      body: body
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response : {}));
  });
};

function* postSaga({ payload, history }) {
  try {
    const response = yield call(postSagaAsync, payload);
    if (response.status === "OK") {
      yield put(postSuccess(response));
    } else {
      yield put(postError(response));
    }
  } catch (e) {
    yield put(postError(e.data ? e.data : e));
  }
}

// END POST
//#######################

//#######################
// PUT
export function* watchPut() {
  yield takeEvery(PUT, putSaga);
}

const putSagaAsync = async (body) => {
  return new Promise(function (resolve, reject) {
    apiService({
      method: "PUT",
      path: CRUD_PUT_PATH + body.id,
      body: body
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response : {}));
  });
};

function* putSaga({ payload }) {
  try {
    const response = yield call(putSagaAsync, payload);
    if (response.status === "OK") {
      yield put(putSuccess(response));
    } else {
      yield put(putError(response));
    }
  } catch (e) {
    yield put(putError(e.data ? e.data : e));
  }
}

// END PUT
//#######################

//#######################
// DELETE
export function* watchDeleteOne() {
  yield takeEvery(DELETE_ONE, deleteOneSaga);
}

const deleteOneSagaAsync = async (objectId) => {
  return new Promise(function (resolve, reject) {
    apiService({
      method: "DELETE",
      path: CRUD_DELETE_PATH + objectId
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response : {}));
  });
};

function* deleteOneSaga({ payload }) {
  try {
    const response = yield call(deleteOneSagaAsync, payload);
    if (response.status === "OK") {
      yield put(deleteOneSuccess(response));
    } else {
      yield put(deleteOneError(response));
    }
  } catch (e) {
    yield put(deleteOneError(e.data ? e.data : e));
  }
}

// END DELETE
//#######################

const acceptOrRejectContractSagaAsync = (body) => {
  return new Promise(function (resolve, reject) {
    apiService({
      method: "POST",
      path: CUSTOM_ENDPOINTS_ACCEPT_OR_REJECT_CONTRACT_PATH,
      body
    })
      .then((res) => resolve({ ...res.data, media: body.mediaURL }))
      .catch((err) =>
        reject(err.response ? err.response : { error: err.message })
      );
  });
};

export function* watchAcceptContract() {
  yield takeEvery(ACCEPT_CONTRACT, acceptContractSaga);
}

function* acceptContractSaga({ payload, onContractAccepted }) {
  try {
    const response = yield call(acceptOrRejectContractSagaAsync, payload);
    if (response.status === "OK") {
      yield put(acceptContractSuccess(response));
      NotificationManager.success(
        "El contrato ha sido aceptado exitosamente",
        "Completado",
        3000
      );
      onContractAccepted();
    } else {
      NotificationManager.warning("Ha ocurrido un error", "Oops!", 3000);
      yield put(acceptContractError(response));
    }
  } catch (error) {
    NotificationManager.warning("Ha ocurrido un error", "Oops!", 3000);
    yield put(acceptContractError(error.data || error));
  }
}

export function* watchRejectContract() {
  yield takeEvery(REJECT_CONTRACT, rejectContractSaga);
}

function* rejectContractSaga({ payload, onContractRejected }) {
  try {
    const response = yield call(acceptOrRejectContractSagaAsync, payload);
    if (response.status === "OK") {
      yield put(rejectContractSuccess(response));
      NotificationManager.success(
        "El contrato ha sido rechazado exitosamente",
        "Completado",
        3000
      );
      onContractRejected();
    } else {
      NotificationManager.warning("Ha ocurrido un error", "Oops!", 3000);
      yield put(rejectContractError(response));
    }
  } catch (error) {
    NotificationManager.warning("Ha ocurrido un error", "Oops!", 3000);
    yield put(rejectContractError(error.data || error));
  }
}

export const getUploadLinkSagaAsync = (contractReference, fileExtension) =>
  apiService({
    method: "POST",
    path: CUSTOM_ENDPOINTS_GET_UPLOAD_LINK,
    body: {
      contractReference,
      fileExtension,
      actionData: "UPLOAD"
    }
  }).then((response) => response.data.data);

export default function* rootSaga() {
  yield all([
    fork(watchList),
    fork(watchGet),
    fork(watchPut),
    fork(watchPost),
    fork(watchDeleteOne),
    fork(watchAcceptContract),
    fork(watchRejectContract)
  ]);
}

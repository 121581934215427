import axios from "axios";
import { Session } from "./session";

const setHeaders = (meta = {}) => {
  const session = new Session();
  let options = {};
  if (session.getSession() && !meta.custom_endpoint) {
    options.headers = {
      authorization: "Bearer " + localStorage.getItem(session.sessionName),

    };
  }
  if (jsonToQueryString(meta.params) !== "?") {
    options.params = meta.params;
  }
  return options;
};

export const jsonToQueryString = (json) => {
  if (json) {
    return (
      "?" +
      Object.keys(json)
        .map(function (key) {
          return encodeURIComponent(key) + "=" + encodeURIComponent(json[key]);
        })
        .join("&")
    );
  }
  return "";
};

export const queryStringToJSON = (query_string) => {
  const pairs = query_string.slice(1).split("&");
  const result = {};
  pairs.forEach(function (pair) {
    pair = pair.split("=");
    result[pair[0]] = decodeURIComponent(pair[1] || "");
  });
  return JSON.parse(JSON.stringify(result));
};

export const paramsSerializer = (params) => {
  let result = "?";
  if (params) {
    Object.keys(params).forEach((key) => {
      result += `${key}=${encodeURIComponent(params[key])}&`;
    });
  }
  return result.substr(0, result.length - 1);
};
const apiService = (meta) => {
  // Path is Required
  if (!meta.path) {
    throw new Error(`'path' not specified for async action ${meta.action}`);
  }
  let addFamososAuthorizationHeader = false;

  // Final URL
  let url = meta.path;
  if (!meta.custom_endpoint) {
    url = `${process.env.REACT_APP_ENDPOINT}${meta.path}`;
    addFamososAuthorizationHeader = true;
  } else {
    url = meta.path;
  }

  let request = axios.create();
  switch (meta.method) {
    case "GET":
    default:
      const params = paramsSerializer(meta.params);
      if (params !== "?") {
        meta.params = {};
        url += params;
      }
      request = axios.get(url, setHeaders(meta, addFamososAuthorizationHeader));
      break;
    case "POST":
      request = axios.post(
        url,
        meta.body,
        setHeaders(meta, addFamososAuthorizationHeader)
      );
      break;
    case "PUT":
      request = axios.put(
        url,
        meta.body,
        setHeaders(meta, addFamososAuthorizationHeader)
      );
      break;
    case "PATCH":
      const params1 = paramsSerializer(meta.params);
      if (params1 !== "?") {
        meta.params = {};
        url += params1;
      }
      request = axios.patch(url, setHeaders(meta, addFamososAuthorizationHeader));
      break;
    case "DELETE":
      request = axios.delete(
        url,
        setHeaders(meta, addFamososAuthorizationHeader)
      );
      break;
  }
  return request;
};

export default apiService;

const MODEL_PATH = "authentication";

//##############################################################
// CRUD

// END CRUD
//##############################################################

//##############################################################
// CUSTOM ACTIONS
// TODO: Write here your custom paths
export const LOGIN_PATH =
	"custom-endpoints/" + MODEL_PATH + "/sign-in/email-password";
export const UPDATE_TERMS =
	"custom-endpoints/" + MODEL_PATH + "/sign-in/terms";
export const RECOVER_PASSWORD_PATH =
	"custom-endpoints/" + MODEL_PATH + "/sign-in/recover-password";
export const VALIDATE_SECURITY_CODE_PATH =
	"custom-endpoints/" + MODEL_PATH + "/sign-in/validate-security-code";
export const REGISTER_PATH =
	"custom-endpoints/" + MODEL_PATH + "/register/email-password";
// CUSTOM ACTIONS
// ##############################################################

import jwt_decode from "jwt-decode";

export class Session {
  constructor() {
    this.sessionName =
      process.env.REACT_APP_ENVIRONMENT === "production"
        ? "prod_admin_for_managers_session"
        : "dev_admin_for_managers_session";
    this.session = this.getSession();
    this.acceptedUserType = "MANAGER";
  }

  setSession = (token) => {
    localStorage.setItem(this.sessionName, token);
  };

  getSession = () => {
    const token = localStorage.getItem(this.sessionName);
    return token ? this.jwtDecode(token) : null;
  };

  removeSession = () => {
    localStorage.removeItem(this.sessionName);
  };

  tokenExpired() {
    const userSession = this.getSession();
    if (
      this.utcSecondsToDateTime(userSession.exp) <= new Date() ||
      userSession.userType !== this.acceptedUserType
    ) {
      this.removeSession();
      return true;
    }
    return false;
  }

  utcSecondsToDateTime = (utcSeconds) => {
    if (utcSeconds) {
      const date = new Date(0); // The 0 there is the key, which sets the date to the epoch
      date.setUTCSeconds(utcSeconds);
      return date;
    } else {
      return null;
    }
  };

  jwtDecode(token) {
    try {
      if (token) {
        return jwt_decode(token);
      }
    } catch (e) {
      return null;
    }
  }
}

import {
    POST_INITIAL_STATE,
    DELETE_ONE_INITIAL_STATE,
    GET_INITIAL_STATE,
    LIST_INITIAL_STATE,
    PUT_INITIAL_STATE
} from "../initial_states";
import {NotificationManager} from "../../components/common/react-notifications";
import {
    POST,
    POST_ERROR,
    POST_SUCCESS,
    GET,
    GET_ERROR,
    GET_SUCCESS,
    LIST,
    LIST_ERROR,
    LIST_SUCCESS,
    PUT,
    PUT_ERROR,
    PUT_SUCCESS,
    DELETE_ONE,
    DELETE_ONE_SUCCESS,
    DELETE_ONE_ERROR,
} from "./constants";


const INIT_STATE = {
    // CRUD
    post: POST_INITIAL_STATE,
    put: PUT_INITIAL_STATE,
    list: LIST_INITIAL_STATE,
    get: GET_INITIAL_STATE,
    deleteOne: DELETE_ONE_INITIAL_STATE,
    // CUSTOM ACTIONS
};

export default (state = INIT_STATE, action) => {
    let res;
    switch (action.type) {
        //##############################################################
        // CRUD
        case POST:
            res = {...state};
            res.post = {...POST_INITIAL_STATE};
            res.post.loading = true;
            return res;
        case POST_SUCCESS:
            res = {...state};
            res.post = {...POST_INITIAL_STATE};
            res.post.data =  action.payload.data;
            return res;
        case POST_ERROR:
            res = {...state};
            res.post = {...POST_INITIAL_STATE};
            res.post.errorData = action.payload;
            NotificationManager.warning(res.post.errorData.error + " in " + POST_ERROR, "Oops!", 3000, null, null, '');
            return res;
        case PUT:
            res = {...state};
            res.put = {...PUT_INITIAL_STATE};
            res.put.loading = true;
            return res;
        case PUT_SUCCESS:
            res = {...state};
            res.put = {...PUT_INITIAL_STATE};
            res.put.data =  action.payload.data;
            return res;
        case PUT_ERROR:
            res = {...state};
            res.put = {...PUT_INITIAL_STATE};
            res.put.errorData = action.payload;
            NotificationManager.warning(res.put.errorData.error + " in " + PUT_ERROR, "Oops!", 3000, null, null, '');
            return res;
        case LIST:
            res = {...state};
            res.list = {...LIST_INITIAL_STATE};
            res.list.loading = true;
            return res;
        case LIST_SUCCESS:
            res = {...state};
            res.list = {...LIST_INITIAL_STATE};
            res.list.results = action.payload.results;
            res.list.informationPage = action.payload.informationPage;
            return res;
        case LIST_ERROR:
            res = {...state};
            res.list = {...LIST_INITIAL_STATE};
            res.list.errorData = action.payload;
            NotificationManager.warning(res.list.errorData.error + " in " + LIST_ERROR, "Oops!", 3000, null, null, '');
            return res;
        case GET:
            res = {...state};
            res.get = {...GET_INITIAL_STATE};
            res.get.loading = true;
            return res;
        case GET_SUCCESS:
            res = {...state};
            res.get = {...GET_INITIAL_STATE};
            res.get.data =  action.payload.data;
            return res;
        case GET_ERROR:
            res = {...state};
            res.get = {...GET_INITIAL_STATE};
            res.get.errorData = action.payload;
            NotificationManager.warning(res.get.errorData.error + " in " + GET_ERROR, "Oops!", 3000, null, null, '');
            return res;
        case DELETE_ONE:
            res = {...state};
            res.delete = {...DELETE_ONE_INITIAL_STATE};
            res.delete.loading = true;
            return res;
        case DELETE_ONE_SUCCESS:
            res = {...state};
            res.delete = {...DELETE_ONE_INITIAL_STATE};
            res.delete.data =  action.payload.data;
            return res;
        case DELETE_ONE_ERROR:
            res = {...state};
            res.delete = {...DELETE_ONE_INITIAL_STATE};
            res.delete.errorData = action.payload;
            NotificationManager.warning(res.delete.errorData.error  + " in " + DELETE_ONE_ERROR, "Oops!", 3000, null, null, '');
            return res;
        // CRUD
        //##############################################################

        //##############################################################
        // CUSTOM ACTIONS
        // TODO: Write here your custom cases

        // CUSTOM ACTIONS
        // ##############################################################


        default:
            return {...state};
    }
}

import { all } from "redux-saga/effects";
import authenticationSagas from "./authentication/saga";
import celebritySagas from "./celebrities/saga";
import countrieSagas from "./countries/saga";
import celebrityCategorySagas from "./celebrity-categories/saga";
import celebrityContractTypeSagas from "./celebrity-contract-types/saga";
import onboardingSagas from "./onboarding/saga";
import contractSagas from "./contracts/saga";
import celebritySocialNetworkSagas from "./celebrity-social-networks/saga";

function* rootSaga(getState) {
	yield all([
		authenticationSagas(),
		celebritySagas(),
		contractSagas(),
		countrieSagas(),
		celebrityCategorySagas(),
		celebrityContractTypeSagas(),
		onboardingSagas(),
		celebritySocialNetworkSagas()
	]);
}

export default rootSaga;

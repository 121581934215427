import { MODEL_PATH } from "./constants";

//##############################################################
// CRUD
export const CRUD_LIST_PATH = "crud/list/" + MODEL_PATH;
export const CRUD_POST_PATH = "crud/post/" + MODEL_PATH;
export const CRUD_GET_PATH = "crud/get/" + MODEL_PATH + "/";
export const CRUD_PUT_PATH = "crud/put/" + MODEL_PATH + "/";
export const CRUD_DELETE_PATH = "crud/delete/" + MODEL_PATH + "/";
// END CRUD
//##############################################################

export const CUSTOM_ENDPOINTS_ACCEPT_OR_REJECT_CONTRACT_PATH = `custom-endpoints/${MODEL_PATH}/accept-or-reject-contract`;
//##############################################################
// CUSTOM ACTIONS
// TODO: Write here your custom paths
export const LIST_PATH = `custom-endpoints/${MODEL_PATH}/list`;

export const GET_BY_USERNAME_PATH =
  "custom-endpoints/" + MODEL_PATH + "/get-user-by-username/";
export const CREATE_PATH = "custom-endpoints/" + MODEL_PATH + "/create";
// CUSTOM ACTIONS
// ##############################################################

export const CUSTOM_ENDPOINTS_GET_UPLOAD_LINK = `custom-endpoints/${MODEL_PATH}/get-upload-link`;

import {
	UPDATE_PASSWORD,
	UPDATE_PASSWORD_SUCCESS,
	UPDATE_PASSWORD_ERROR,
	COMPLETE_ACCOUNT_DATA,
	COMPLETE_ACCOUNT_DATA_SUCCESS,
	COMPLETE_ACCOUNT_DATA_ERROR
} from "./constants";

//##############################################################
// CRUD

// END CRUD
//##############################################################

//##############################################################
// UPDATE PASSWORD
export const updatePassword = (payload, history) => ({
	type: UPDATE_PASSWORD,
	payload,
	history
});

export const updatePasswordSuccess = (payload, history) => ({
	type: UPDATE_PASSWORD_SUCCESS,
	payload
});

export const updatePasswordError = (payload, history) => ({
	type: UPDATE_PASSWORD_ERROR,
	payload
});
// UODATE PASSWORD
// ##############################################################

//##############################################################
// COMPLETE ACCOUNT DATA
export const completeAccountData = (payload, history) => ({
	type: COMPLETE_ACCOUNT_DATA,
	payload,
	history
});

export const completeAccountDataSuccess = (payload, history) => ({
	type: COMPLETE_ACCOUNT_DATA_SUCCESS,
	payload
});

export const completeAccountDataError = (payload, history) => ({
	type: COMPLETE_ACCOUNT_DATA_ERROR,
	payload
});
// COMPLETE ACCOUNT DATA
// ##############################################################

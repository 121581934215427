//##############################################################
// CRUD

// END CRUD
//##############################################################

//##############################################################
// UODATE PASSWORD
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR";
// UODATE PASSWORD
// ##############################################################
//##############################################################
// COMPLETE ACCOUNT DATA
export const COMPLETE_ACCOUNT_DATA = "COMPLETE_ACCOUNT_DATA";
export const COMPLETE_ACCOUNT_DATA_SUCCESS = "COMPLETE_ACCOUNT_DATA_SUCCESS";
export const COMPLETE_ACCOUNT_DATA_ERROR = "COMPLETE_ACCOUNT_DATA_ERROR";
// COMPLETE ACCOUNT DATA
// ##############################################################

export const ERROR_INITIAL_STATE = {
  title: "Oops!",
  error: "An error happened"
};

export const LOGIN_INITIAL_STATE = {
  loading: false,
  errorData: ERROR_INITIAL_STATE,
  token: null
};

export const REGISTER_INITIAL_STATE = {
  loading: false,
  errorData: ERROR_INITIAL_STATE,
  token: null
};

export const CREATE_INITIAL_STATE = {
  loading: false,
  errorData: ERROR_INITIAL_STATE
};

export const SET_REDIRECT_UNAUTHORIZED_INITIAL_STATE = {
  shouldRedirectUnauthorized: false
};

export const RECOVER_PASSWORD_INITIAL_STATE = {
  loading: false,
  errorData: ERROR_INITIAL_STATE,
  recoveryEmail: null
};

export const VALIDATE_SECURITY_CODE_INITIAL_STATE = {
  loading: false,
  errorData: ERROR_INITIAL_STATE,
  token: null
};

export const UPDATE_PASSWORD_INITIAL_STATE = {
  loading: false,
  errorData: ERROR_INITIAL_STATE,
  data: {}
};

export const COMPLETE_ACCOUNT_DATA_INITIAL_STATE = {
  loading: false,
  errorData: ERROR_INITIAL_STATE,
  data: {}
};

export const CONTRACT_VIDEO_UPLOAD_INITIAL_STATE = {
  isUploading: false,
  errorData: ERROR_INITIAL_STATE,
  data: {}
};

export const CONTRACT_REJECT_INITIAL_STATE = {
  isLoading: false,
  errorData: ERROR_INITIAL_STATE,
  data: {}
};

export const POST_INITIAL_STATE = {
  loading: false,
  errorData: ERROR_INITIAL_STATE,
  data: {}
};

export const PUT_INITIAL_STATE = {
  loading: false,
  errorData: ERROR_INITIAL_STATE,
  data: {}
};

export const LIST_INITIAL_STATE = {
  loading: false,
  errorData: ERROR_INITIAL_STATE,
  results: [],
  informationPage: {
    totalItems: 0,
    totalPages: 0,
    pageSize: 12,
    currentPage: 1
  }
};

export const GET_INITIAL_STATE = {
  loading: false,
  errorData: ERROR_INITIAL_STATE,
  data: {}
};

export const DELETE_ONE_INITIAL_STATE = {
  loading: false,
  errorData: ERROR_INITIAL_STATE,
  data: {}
};

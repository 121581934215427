export const MODEL_PATH = "celebrities";

//##############################################################
// CRUD
export const POST = MODEL_PATH + "_POST";
export const POST_SUCCESS = MODEL_PATH + "_POST_SUCCESS";
export const POST_ERROR = MODEL_PATH + "_POST_ERROR";
export const LIST = MODEL_PATH + "_LIST";
export const LIST_SUCCESS = MODEL_PATH + "_LIST_SUCCESS";
export const LIST_ERROR = MODEL_PATH + "_LIST_ERROR";
export const GET = MODEL_PATH + "_GET";
export const GET_SUCCESS = MODEL_PATH + "_GET_SUCCESS";
export const GET_ERROR = MODEL_PATH + "_GET_ERROR";
export const PUT = MODEL_PATH + "_PUT";
export const PUT_SUCCESS = MODEL_PATH + "_PUT_SUCCESS";
export const PUT_ERROR = MODEL_PATH + "_PUT_ERROR";
export const DELETE_ONE = MODEL_PATH + "_DELETE_ONE";
export const DELETE_ONE_SUCCESS = MODEL_PATH + "_DELETE_ONE_SUCCESS";
export const DELETE_ONE_ERROR = MODEL_PATH + "_DELETE_ONE_ERROR";
// END CRUD
//##############################################################

//##############################################################
// CUSTOM ACTIONS
// TODO: Write here your custom action constants
export const GET_BY_USERNAME = MODEL_PATH + "_GET_BY_USERNAME";
export const GET_BY_USERNAME_SUCCESS = MODEL_PATH + "_GET_BY_USERNAME_SUCCESS";
export const GET_BY_USERNAME_ERROR = MODEL_PATH + "_GET_BY_USERNAME_ERROR";
export const CREATE = MODEL_PATH + "_CREATE";
export const CREATE_SUCCESS = MODEL_PATH + "_CREATE_SUCCESS";
export const CREATE_ERROR = MODEL_PATH + "_CREATE_ERROR";
export const UPDATE = MODEL_PATH + "_UPDATE";
export const UPDATE_SUCCESS = MODEL_PATH + "_UPDATE_SUCCESS";
export const UPDATE_ERROR = MODEL_PATH + "_UPDATE_ERROR";
// CUSTOM ACTIONS
// ##############################################################

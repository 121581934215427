import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  LOGIN,
  LOGOUT,
  RECOVER_PASSWORD,
  VALIDATE_SECURITY_CODE,
} from "./constants";
import { Session } from "../session";
import {
  loginError,
  loginModal,
  loginSuccess,
  recoverPasswordError,
  recoverPasswordSuccess,
  validateSecurityCodeError,
  validateSecurityCodeSuccess,
} from "./actions";
import {
  ON_BOARDING_UPDATE_PASSWORD_PATH,
  ON_BOARDING_COMPLETE_ACCOUNT_DATA_PATH,
  AUTHENTICATION_FORGOT_PASSWORD_VALIDATE_SECURITY_CODE_PATH,
  ROOT_PATH,
} from "../../routing/paths";
import apiService from "../api_service";
import {
  LOGIN_PATH,
  RECOVER_PASSWORD_PATH,
  VALIDATE_SECURITY_CODE_PATH,
  UPDATE_TERMS,
} from "./paths";
import {
  UPDATE_PASSWORD,
  COMPLETE_ACCOUNT_DATA,
} from "../../constants/authenticationRequiredActions";
import { NotificationManager } from "../../components/common/react-notifications";

//#######################
// LOGIN
export function* watchLogin() {
  yield takeEvery(LOGIN, loginWithEmailPassword);
}

const redirectUser = (requiredAction, history) => {
  let redirectRoute = ROOT_PATH;
  if (requiredAction === UPDATE_PASSWORD) {
    redirectRoute = ON_BOARDING_UPDATE_PASSWORD_PATH;
  }
  if (requiredAction === COMPLETE_ACCOUNT_DATA) {
    redirectRoute = ON_BOARDING_COMPLETE_ACCOUNT_DATA_PATH;
  }
  history.push(redirectRoute);
};

/*
{
  path: "custom-endpoints/authentication/create-account",
  body: {
    email: 'germandevs@gmail.com',
    fullName: 'German Salazar',
    password: '123456',
    isAnAgency: false,
    agencyName: '',
    agencyLogo: ''
  }
}
*/
const loginWithEmailPasswordAsync = async (email, password) => {
  try {
    const response = await apiService({
      async: true,
      path: LOGIN_PATH,
      method: "POST",
      body: { email, password },
    });
    console.log(response);
    return response.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { error: "Error inesperado del servidor" };
  }
};

const updateTermsWithEmailPassword = async (termsAccepted, token) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + token);
    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      process.env.REACT_APP_ENDPOINT +
        "custom-endpoints/authentication/sign-in/terms?accept=" +
        termsAccepted,
      requestOptions
    );
  } catch (error) {
    return error.response
      ? error.response.data
      : { error: "Error inesperado del servidor" };
  }
};

function* loginWithEmailPassword({ payload, history }) {
  const { email, password, termsAccepted } = payload;
  try {
    const loginData = yield call(loginWithEmailPasswordAsync, email, password);
    if (loginData.status !== "OK") throw loginData.error;
    const terms_accepted = loginData.data.managerData.termsAccepted;
    if (!terms_accepted) {
      if (termsAccepted) {
        updateTermsWithEmailPassword(termsAccepted, loginData.data.token);
      } else {
        yield put(loginModal(true));
        return;
      }
    }
    yield put(loginSuccess(loginData.data.token));
    new Session().setSession(loginData.data.token);
    redirectUser(loginData.data.requiredAction, history);
  } catch (error) {
    yield put(loginError(error));
    NotificationManager.warning(error, "Oops!", 3000);
  }
}

// END LOGIN
//#######################
//#######################
// RECOVER PASSWORD
export function* watchRecoverPassword() {
  yield takeEvery(RECOVER_PASSWORD, recoverPassword);
}

const recoverPasswordAsync = async (body) => {
  try {
    const response = await apiService({
      async: true,
      path: RECOVER_PASSWORD_PATH,
      method: "POST",
      params: null,
      body,
    });
    return response.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { error: "Error inesperado del servidor" };
  }
};

function* recoverPassword({ payload, history }) {
  try {
    const recoverPasswordData = yield call(recoverPasswordAsync, payload);
    if (recoverPasswordData.status !== "OK") {
      throw recoverPasswordData.error;
    }
    yield put(recoverPasswordSuccess(payload.email));
    history.push(AUTHENTICATION_FORGOT_PASSWORD_VALIDATE_SECURITY_CODE_PATH);
  } catch (error) {
    yield put(recoverPasswordError(error));
    NotificationManager.warning(error, "Oops!", 3000);
  }
}

// END RECOVER PASSWORD
//#######################
//#######################
// RECOVER PASSWORD
export function* watchValidateSecurityCode() {
  yield takeEvery(VALIDATE_SECURITY_CODE, validateSecurityCode);
}

const validateSecurityCodeAsync = async (body) => {
  try {
    const response = await apiService({
      async: true,
      path: VALIDATE_SECURITY_CODE_PATH,
      method: "POST",
      params: null,
      body,
    });
    return response.data;
  } catch (error) {
    return error.response
      ? error.response.data
      : { error: "Error inesperado del servidor" };
  }
};

function* validateSecurityCode({ payload, history }) {
  try {
    const validateSecurityCodeData = yield call(
      validateSecurityCodeAsync,
      payload
    );
    if (validateSecurityCodeData.status !== "OK") {
      throw validateSecurityCodeData.error;
    }
    yield put(validateSecurityCodeSuccess(validateSecurityCodeData.data.token));
    new Session().setSession(validateSecurityCodeData.data.token);
    history.push(ON_BOARDING_UPDATE_PASSWORD_PATH);
  } catch (error) {
    yield put(validateSecurityCodeError(error));
    NotificationManager.warning(error, "Oops!", 3000);
  }
}

// END RECOVER PASSWORD
//#######################
//#######################
// LOGOUT
export function* watchLogout() {
  yield takeEvery(LOGOUT, logoutAsyc);
}

function* logoutAsyc({ payload: history }) {
  try {
    new Session().removeSession();
    history.push && history.push(ROOT_PATH);
  } catch (error) {
    NotificationManager.warning(error.message, "Oops!", 3000);
  }
}

// END LOGOUT
//#######################

export default function* rootSaga() {
  yield all([
    fork(watchLogin),
    fork(watchRecoverPassword),
    fork(watchValidateSecurityCode),
    fork(watchLogout),
  ]);
}

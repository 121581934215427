import {
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  COMPLETE_ACCOUNT_DATA,
  COMPLETE_ACCOUNT_DATA_SUCCESS,
  COMPLETE_ACCOUNT_DATA_ERROR
} from "./constants";

import {
  UPDATE_PASSWORD_INITIAL_STATE,
  COMPLETE_ACCOUNT_DATA_INITIAL_STATE
} from "../initial_states";

const INIT_STATE = {
  updatePassword: UPDATE_PASSWORD_INITIAL_STATE,
  completeAccountData: COMPLETE_ACCOUNT_DATA_INITIAL_STATE
};

export default (state = INIT_STATE, action) => {
  let res;
  switch (action.type) {
    case UPDATE_PASSWORD:
      res = { ...state };
      res.updatePassword = {
        ...UPDATE_PASSWORD_INITIAL_STATE,
        loading: true
      };
      return res;
    case UPDATE_PASSWORD_SUCCESS:
      res = { ...state };
      res.updatePassword = {
        ...UPDATE_PASSWORD_INITIAL_STATE,
        loading: false,
        data: action.payload
      };
      return res;
    case UPDATE_PASSWORD_ERROR:
      res = { ...state };
      res.updatePassword = {
        ...UPDATE_PASSWORD_INITIAL_STATE,
        errorData: action.payload
      };
      return res;
    case COMPLETE_ACCOUNT_DATA:
      res = { ...state };
      res.completeAccountData = {
        ...COMPLETE_ACCOUNT_DATA_INITIAL_STATE,
        loading: true
      };
      return res;
    case COMPLETE_ACCOUNT_DATA_SUCCESS:
      res = { ...state };
      res.completeAccountData = {
        ...COMPLETE_ACCOUNT_DATA_INITIAL_STATE,
        loading: false,
        data: action.payload
      };
      return res;
    case COMPLETE_ACCOUNT_DATA_ERROR:
      res = { ...state };
      res.completeAccountData = {
        ...COMPLETE_ACCOUNT_DATA_INITIAL_STATE,
        errorData: action.payload
      };
      return res;
    default:
      return { ...state };
  }
};

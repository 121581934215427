import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import apiService from "../api_service";
import {
  DELETE_ONE,
  GET,
  LIST,
  NOT_PAGINATED_LIST,
  POST,
  PUT
} from "./constants";
import {
  deleteOneError,
  deleteOneSuccess,
  getError,
  getSuccess,
  listError,
  listSuccess,
  notPaginatedListError,
  notPaginatedListSuccess,
  postError,
  postSuccess,
  putError,
  putSuccess
} from "./actions";
import {
  CRUD_DELETE_PATH,
  CRUD_GET_PATH,
  CRUD_LIST_PATH,
  CRUD_NOT_PAGINATED_LIST_PATH,
  CRUD_POST_PATH,
  CRUD_PUT_PATH
} from "./paths";

//#######################
// LIST
export function* watchList() {
  yield takeEvery(LIST, listSaga);
}

const listSagaAsync = async (params) => {
  return new Promise(function (resolve, reject) {
    apiService({
      method: "GET",
      path: CRUD_LIST_PATH,
      params: params
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response.data : {}));
  });
};

function* listSaga({ payload }) {
  try {
    const response = yield call(listSagaAsync, payload);
    if (response.status === "OK") {
      yield put(listSuccess(response));
    } else {
      yield put(listError(response));
    }
  } catch (e) {
    yield put(listError(e));
  }
}

// END LIST
//#######################
//#######################
// LIST
export function* watchNotPaginatedList() {
  yield takeEvery(NOT_PAGINATED_LIST, notPaginatedListSaga);
}

const notPaginatedListSagaAsync = async (params) => {
  return new Promise(function (resolve, reject) {
    apiService({
      method: "GET",
      path: CRUD_NOT_PAGINATED_LIST_PATH,
      params: params
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response.data : {}));
  });
};

function* notPaginatedListSaga({ payload }) {
  try {
    const response = yield call(notPaginatedListSagaAsync, payload);
    if (response.status === "OK") {
      yield put(notPaginatedListSuccess(response));
    } else {
      yield put(notPaginatedListError(response));
    }
  } catch (e) {
    yield put(notPaginatedListError(e));
  }
}

// END LIST
//#######################
//#######################
// GET
export function* watchGet() {
  yield takeEvery(GET, getSaga);
}

const getSagaAsync = async (objectId) => {
  return new Promise(function (resolve, reject) {
    apiService({
      method: "GET",
      path: CRUD_GET_PATH + objectId,
      custom_endpoint: "https://api.myjson.com/bins/12zp2o"
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response.data : {}));
  });
};

function* getSaga({ payload }) {
  try {
    const response = yield call(getSagaAsync, payload);
    if (response.status === "OK") {
      yield put(getSuccess(response));
    } else {
      yield put(getError(response));
    }
  } catch (e) {
    yield put(getError(e));
  }
}

// END GET
//#######################

//#######################
// POST
export function* watchPost() {
  yield takeEvery(POST, postSaga);
}

const postSagaAsync = async (body) => {
  return new Promise(function (resolve, reject) {
    apiService({
      method: "POST",
      path: CRUD_POST_PATH,
      body: body
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response.data : {}));
  });
};

function* postSaga({ payload }) {
  try {
    const response = yield call(postSagaAsync, payload);
    if (response.status === "OK") {
      yield put(postSuccess(response));
    } else {
      yield put(postError(response));
    }
  } catch (e) {
    yield put(postError(e));
  }
}

// END POST
//#######################

//#######################
// PUT
export function* watchPut() {
  yield takeEvery(PUT, putSaga);
}

const putSagaAsync = async (objectId, body) => {
  return new Promise(function (resolve, reject) {
    apiService({
      method: "PUT",
      path: CRUD_PUT_PATH + objectId,
      body: body
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response.data : {}));
  });
};

function* putSaga({ payload }) {
  try {
    const response = yield call(putSagaAsync, payload);
    if (response.status === "OK") {
      yield put(putSuccess(response));
    } else {
      yield put(putError(response));
    }
  } catch (e) {
    yield put(putError(e));
  }
}

// END PUT
//#######################

//#######################
// DELETE
export function* watchDeleteOne() {
  yield takeEvery(DELETE_ONE, deleteOneSaga);
}

const deleteOneSagaAsync = async (objectId) => {
  return new Promise(function (resolve, reject) {
    apiService({
      method: "DELETE",
      path: CRUD_DELETE_PATH + objectId
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response ? err.response.data : {}));
  });
};

function* deleteOneSaga({ payload }) {
  try {
    const response = yield call(deleteOneSagaAsync, payload);
    if (response.status === "OK") {
      yield put(deleteOneSuccess(response));
    } else {
      yield put(deleteOneError(response));
    }
  } catch (e) {
    yield put(deleteOneError(e));
  }
}

// END DELETE
//#######################

export default function* rootSaga() {
  yield all([
    fork(watchList),
    fork(watchNotPaginatedList),
    fork(watchGet),
    fork(watchPut),
    fork(watchPost),
    fork(watchDeleteOne)
  ]);
}

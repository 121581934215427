export const ROOT_PATH = "/";

//####################################
// DASHBOARDS
export const DASHBOARD_ROOT_PATH = `/dashboards`;
export const DASHBOARD_CELEBRITIES_PATH = `${DASHBOARD_ROOT_PATH}/celebrities`;
export const DASHBOARD_CONTRACTS_PATH = `${DASHBOARD_ROOT_PATH}/contracts`;
export const DASHBOARD_BUSINESS_CONTRACTS_PATH = `${DASHBOARD_ROOT_PATH}/business-contracts`;
// END DASHBOARDS
//####################################

//####################################
// CELEBRITIES
export const CELEBRITIES_ROOT_PATH = `/celebrities`;
export const CELEBRITIES_LIST_PATH = `${CELEBRITIES_ROOT_PATH}/list`;
export const CELEBRITIES_DETAILS_PATH = `${CELEBRITIES_ROOT_PATH}/:username/details`;
export const CELEBRITIES_EDITOR_PATH = `${CELEBRITIES_ROOT_PATH}/:username/editor`;
// export const CELEBRITIES_UPDATE_PATH = `${CELEBRITIES_ROOT_PATH}/update`;
export const CELEBRITIES_CREATOR_PATH = `${CELEBRITIES_ROOT_PATH}/creator`;
// END CELEBRITIES
//####################################

//####################################
// CONTRACTS
export const CONTRACTS_ROOT_PATH = `/contracts`;
export const CONTRACTS_LIST_PATH = `${CONTRACTS_ROOT_PATH}/list`;
export const CONTRACTS_DETAILS_PATH = `${CONTRACTS_ROOT_PATH}/:username/details`;
export const CONTRACTS_CREATOR_PATH = `${CONTRACTS_ROOT_PATH}/creator`;
// END CONTRACTS
//####################################

//####################################
// ACCOUNT_SETTINGS
export const ACCOUNT_SETTINGS_ROOT_PATH = `/account-settings`;
export const ACCOUNT_SETTINGS_COMPANY_DATA_PATH = `${ACCOUNT_SETTINGS_ROOT_PATH}/company-data`;
// END ACCOUNT_SETTINGS
//####################################

//####################################
// FAQS
export const FAQS_ROOT_PATH = `/faqs`;
// END FAQS
//####################################

export const APP_ROOT_PATH = "/celebrities";
export const APP_HOME_PATH = `${APP_ROOT_PATH}/home`;

export const APP_ACCOUNT_SETTINGS_PATH = `${APP_ROOT_PATH}/account-settings`;
export const APP_FAQS_PATH = `${APP_ROOT_PATH}/faqs`;
export const DOCS_TERMS_COND = `/terms`;

//AUTHENTICATION ROUTES
export const AUTHENTICATION_ROOT_PATH = "/authentication";
export const AUTHENTICATION_LOGIN_PATH = `${AUTHENTICATION_ROOT_PATH}/login`;
export const AUTHENTICATION_REGISTER_PATH = `${AUTHENTICATION_ROOT_PATH}/register`;
export const AUTHENTICATION_FORGOT_PASSWORD_PATH = `${AUTHENTICATION_ROOT_PATH}/forgot-password`;
export const AUTHENTICATION_FORGOT_PASSWORD_VALIDATE_SECURITY_CODE_PATH = `${AUTHENTICATION_FORGOT_PASSWORD_PATH}/validate-security-code`;
export const AUTHENTICATION_RESET_PASSWORD_PATH = `${AUTHENTICATION_ROOT_PATH}/reset-password`;

//####################################
// ONBOARDING
export const ON_BOARDING_ROOT_PATH = "/onboarding";
export const ON_BOARDING_UPDATE_PASSWORD_PATH = `${ON_BOARDING_ROOT_PATH}/update-password`;
export const ON_BOARDING_COMPLETE_ACCOUNT_DATA_PATH = `${ON_BOARDING_ROOT_PATH}/complete-account-data`;
// END ONBOARDING
//####################################

//ERRORS APP
export const ERROR_ROOT_PATH = "/error";
export const ERROR_NOT_FOUND_PATH = `${ERROR_ROOT_PATH}/not-found`;
